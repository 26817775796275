<template>
  <v-menu :disabled="!movable" v-if="createMove">
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        color="primary lighten-1"
        class="mr-2"
        v-on="on"
        :disabled="!movable"
      >
        <v-icon>
          fa fa-arrows-alt
        </v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="action in moveActionTypes"
        :key="action"
        :disabled="hasMoveNode"
        @click="startMove(action)"
      >
        <v-list-item-title>
          {{`action.tree_node.move.${action}`| t}}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="hasMoveNode" @click="clearMove">
        <v-list-item-title>
          {{'action.cancel'| t}}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="babel" type="text/babel">
import treeNodeAction from '@/components/tree/nodeAction/treeNodeAction'
export default {
  mixins: [treeNodeAction],
  methods: {
    startMove(action) {
      if(action === 'up') {
        this.moveUp()
        return
      }

      this.$store.dispatch(`tree/${this.treeKey}/move`, {
        node: this.item.id,
        parent: this.item.parent_id,
        action,
      })
    },
    async moveUp() {
      await this.$nextTick()
      this.callMoveApi(this.item.id, this.item.parent_id, 'before')

      this.clearMove()
      this.treeActions.refreshTree()
    },
  },
  computed: {
    moveActionTypes() {
      if(this.item.depth <= 1) return ['inside', 'before', 'after']
      return ['inside', 'before', 'after', 'up']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>